import { Overlay } from '@angular/cdk/overlay';
import { NgClass, NgFor, NgIf, NgTemplateOutlet } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, HostBinding, inject, Input, OnChanges, OnDestroy, OnInit, Output, Renderer2, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { MAT_AUTOCOMPLETE_SCROLL_STRATEGY, MatAutocomplete, MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButton, MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { NavigationEnd, Router, RouterLink } from '@angular/router';
import { fuseAnimations } from '@fuse/animations/public-api';
import { debounceTime, filter, map, Subject, takeUntil } from 'rxjs';
import { SearchService } from './search.service';
import { UsuarioService } from 'app/modules/administracion/usuario/services/usuario.service';

@Component({
    selector: 'search',
    templateUrl: './search.component.html',
    encapsulation: ViewEncapsulation.None,
    exportAs: 'fuseSearch',
    animations: fuseAnimations,
    standalone: true,
    imports: [NgIf, MatButtonModule, MatIconModule, FormsModule, MatAutocompleteModule, ReactiveFormsModule, MatOptionModule, NgFor, RouterLink, NgTemplateOutlet, MatFormFieldModule, MatInputModule, MatIconModule, NgClass],
    providers: [
        {
            provide: MAT_AUTOCOMPLETE_SCROLL_STRATEGY,
            useFactory: () => {
                const overlay = inject(Overlay);
                return () => overlay.scrollStrategies.block();
            },
        }, UsuarioService
    ],
})
export class SearchComponent implements OnChanges, OnInit, OnDestroy {
    @Input() appearance: 'basic' | 'bar' = 'basic';
    @Input() debounce: number = 300;
    @Input() minLength: number = 2;
    @Output() search: EventEmitter<any> = new EventEmitter<any>();

    opened: boolean = false;
    resultSets: any[];
    searchControl: UntypedFormControl = new UntypedFormControl();
    private _matAutocomplete: MatAutocomplete;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    textoBusqueda = new Subject<string>();
    filtroGeneral: number = 1;

    /**
     * Constructor
     */
    constructor(
        private _elementRef: ElementRef,
        private _httpClient: HttpClient,
        private _renderer2: Renderer2,
        private _searchService: SearchService,
        private _usuarioService: UsuarioService,
        private router: Router
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Host binding for component classes
     */
    @HostBinding('class') get classList(): any {
        return {
            'search-appearance-bar': this.appearance === 'bar',
            'search-appearance-basic': this.appearance === 'basic',
            'search-opened': this.opened,
        };
    }

    /**
     * Setter for bar search input
     *
     * @param value
     */
    @ViewChild('barSearchInput')
    set barSearchInput(value: ElementRef) {
        // If the value exists, it means that the search input
        // is now in the DOM, and we can focus on the input..
        if (value) {
            // Give Angular time to complete the change detection cycle
            setTimeout(() => {
                // Focus to the input element
                value.nativeElement.focus();
            });
        }
    }

    /**
     * Setter for mat-autocomplete element reference
     *
     * @param value
     */
    @ViewChild('matAutocomplete')
    set matAutocomplete(value: MatAutocomplete) {
        this._matAutocomplete = value;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    ngOnChanges(changes: SimpleChanges): void {
        // Appearance
        this.textoBusqueda = new Subject<string>();
        if ('appearance' in changes) {
            // To prevent any issues, close the
            // search after changing the appearance
            this.close(true);
        }
    }

    /**
     * On init
     */
    ngOnInit(): void {
        this.router.events
        .pipe(filter(event => event instanceof NavigationEnd))
        .subscribe((event: NavigationEnd) => {
            if (event.urlAfterRedirects.includes('/usuarios')) {
            this._usuarioService.getUsuarios().subscribe((response: any) => {
                if (response.mostrar_empresa === 0) {
                this.filtroGeneral = 0;
                }
            });
            } else {
                this.filtroGeneral = 1;
            }
        });
        this._searchService.setSearchInput(new FormControl());

        // // Subscribe to the search field value changes
        // this.searchControl.valueChanges
        //     .pipe(
        //         debounceTime(this.debounce),
        //         takeUntil(this._unsubscribeAll),
        //         map((value) =>
        //         {
        //             // Set the resultSets to null if there is no value or
        //             // the length of the value is smaller than the minLength
        //             // so the autocomplete panel can be closed
        //             if ( !value || value.length < this.minLength )
        //             {
        //                 this.resultSets = null;
        //             }

        //             // Continue
        //             return value;
        //         }),
        //         // Filter out undefined/null/false statements and also
        //         // filter out the values that are smaller than minLength
        //         filter(value => value && value.length >= this.minLength),
        //     )
        //     .subscribe((value) =>
        //     {
        //         console.log('aiuda',value)
        // this._httpClient.post('api/common/search', {query: value})
        //     .subscribe((resultSets: any) =>
        //     {
        //         // Store the result sets
        //         this.resultSets = resultSets;

        //         // Execute the event
        //         this.search.next(resultSets);
        // });
        // });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * On keydown of the search input
     *
     * @param event
     */
    onKeydown(event: KeyboardEvent): void {
        // Escape
        if ((event.code === 'Escape' || event.code === 'Enter') && this.appearance === 'bar') {
            // If the appearance is 'bar' and the mat-autocomplete is not open, close the search
            this.close(event.code === 'Escape');
        }
    }

    /**
   * Open the search
   * Used in 'bar'
   */
    open(): void {
        // Return if it's already opened
        if (this.opened) {
            return;
        }
        this.textoBusqueda = new Subject<string>();
        // Open the search
        this.opened = true;
    }

    /**
     * Esta función sirve para cerrar la barra de busqueda
     * @param clear 
     * @returns 
     */
    close(clear: boolean): void {
        // Return if it's already closed
        if (!this.opened) {
            return;
        }
        if (clear) {
            // Clear the search input
            this._searchService.getSearchInput().setValue('');
        }
        // Close the search
        this.opened = false;
    }

    /**
     * Esta función sirve para la obtencion de la referencia del filtro y datos
     * @param event Evento que llamo al filtro
     * @param origin Elemento que desencadeno el la activacion del filtro
     */
    eventoFiltro(event: Event, origin: MatButton) {
        this._searchService.setFilterOrigin(origin)
        this._searchService.getFilterEvent().next(event)
    }

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any {
        return item.id || index;
    }
}
