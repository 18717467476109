import { Injectable } from "@angular/core";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class HeaderTextService {
  private titulo: string = '';
  private nombre: string = '';

  /**
   * Getter for titulo
   */
  getTitulo(): string {
    return this.titulo || '';
  }

  setTitulo(route: Router, nombre?: string) {
    this.titulo = route.url;
    if (nombre) {
      this.nombre = nombre;
    }
  }

  getNombre(): string {
    return this.nombre || '';
  }
}
