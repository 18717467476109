import { Title } from '@angular/platform-browser';
import { RouterOutlet } from '@angular/router';
import { TokenService } from '@shared/service/app/token.service';
import { Component, OnInit } from '@angular/core';

@Component({
    selector   : 'app-root',
    templateUrl: './app.component.html',
    styleUrls  : ['./app.component.scss'],
    standalone : true,
    imports    : [RouterOutlet],
})
export class AppComponent implements OnInit {
    /**
     * Constructor
     */
    constructor(
        // private _tokenService: TokenService,
    ) {}

    ngOnInit(): void {
    }
}