import { HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { NavigationService } from '@core/navigation/navigation.service';
import { AuthService } from 'app/core/auth/auth.service';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { catchError, Observable, throwError } from 'rxjs';

/**
 * Intercept
 *
 * @param req
 * @param next
 */
export const authInterceptor = (req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> => {
    const authService = inject(AuthService);
    const navigationService = inject(NavigationService);
    const _router = inject(Router);

    // Clone the request object
    let newReq = req.clone();

    // Request
    if (authService.accessToken && !AuthUtils.isTokenExpired(authService.accessToken)) {
        newReq = req.clone({
            headers: req.headers.set('Authorization', 'Bearer ' + authService.accessToken),
        });
        
        // Modificar esta condición para evitar el bucle infinito
        if ((_router.url !== "/") && (_router.url !== "/seleccion-roles") && !navigationService.isMenuLoaded) {
            navigationService.getMenus();
        }
    }

    // Response
    return next(newReq).pipe(
        catchError((error) => {

            // Capta errores desconocidos
            if (error.status === 0) {
                authService.signOut()
                //Reload tge app
                location.reload()
                return throwError(() => new Error('Token expirado u inexistente'));                //Sign out
            }

            // Capta errores desconocidos
            if (error.status === 500) {
                if (error.error.code == 23000) {
                    return throwError(() => new Error('No puede eliminar un item que tiene elementos asociados.'));
                }
               authService.signOut()
                //Reload tge app
               location.reload()
                return throwError(() => new Error('Error interno del servidor'));                //Sign out
            }

            // Catch "401 Unauthorized" responses
            if (error instanceof HttpErrorResponse && error.status === 401) {
                // Sign out
                authService.signOut();

                // Reload the app
                location.reload();
                return throwError(() => new Error('Credenciales invalidas.'));
            }

            // Asegúrate de que isMenuLoaded se restablezca a false cuando se cierre la sesión
            if (error instanceof HttpErrorResponse && error.status === 401) {
                navigationService.isMenuLoaded = false;
            }

            // ... resto del código para manejar otros errores ...
        }),
    );
};

