const $BASE_API = "https://apituregistro.desajeds.com.ar";
const $BASE_REST = "https://apituregistro.desajeds.com.ar/web";
const $TUREGISTRO = "https://inscripciondev.jeds.ar"
//const $BASE_REST = "http://localhost/tu_registro_backend/web";
// const $BASE_REST = "http://192.168.0.174/tu_registro_backend/web";

export const environment = {
      production: false,
      baseRest: $BASE_REST,
      turegristro: $TUREGISTRO,
      login: {
            users: `${$BASE_REST}/view_usuario_logins`,
            //users: `${$CARGA_WEB}/usuarios_login.php`,
            login: `${$BASE_REST}/pev_seg_usuario/login`,
            view_usuarios: `${$BASE_REST}/view_usuarios`
      },

      controlador: `${$BASE_API}/controlador`,
      menu: `${$BASE_REST}/pev_com_menus`,
      pev_seg_usuario: {
            token: `${$BASE_REST}/pev_seg_usuario/token`,
            roles: `${$BASE_REST}/pev_seg_usuario/roles`,
      },
      administracion: {
            empresas: {
                  view_empresa: `${$BASE_REST}/view_empresas`,
                  empresa: `${$BASE_REST}/pev_emp_empresas`,
                  ver_logo_empresa: `${$BASE_REST}` // uploads/nombre_imagen se lo concatena en save.component.ts
            },
            usuarios: {
                  usuario_datas: `${$BASE_REST}/pev_seg_usuario_datas`,
                  view_usuario: `${$BASE_REST}/view_usuarios`,
                  usuarioRoles: `${$BASE_REST}/pev_seg_usuario_data/roles`,
                  inactivarUsuarioRoles: `${$BASE_REST}/pev_seg_usuario_data/inactivar_rol`,
                  getDueniosAsignarEmpresa: `${$BASE_REST}/pev_seg_usuario_data/get_duenos`,
                  asignarEmpresaGuardar: `${$BASE_REST}/pev_seg_usuario_data/asignar_duenos`,
                  asignarRolAUsuario: `${$BASE_REST}/pev_seg_usuario_data/get_rol`,
                  asignarRolAUsuarioGuardar: `${$BASE_REST}/pev_seg_usuario_data/asignar_rol`,
                  ver_foto_usuario: `${$BASE_REST}`, // uploads/nombre_imagen se lo concatena en save.component.ts
                  get_roles:`${$BASE_REST}/pev_seg_usuario_data/get_roles`
            }

      },
      proyectos: {
            proyectos: {
                  view_proyectos: `${$BASE_REST}/view_proyectos`,
                  proyectos: `${$BASE_REST}/pev_pro_proyectos`,
                  rechazar_proyecto: `${$BASE_REST}/pev_pro_proyecto/rechazar`,
                  aprobar_proyecto: `${$BASE_REST}/pev_pro_proyecto/aprobar`,
                  rendiciones: `${$BASE_REST}/pev_pro_proyecto/rendiciones`,
                  rendicion: `${$BASE_REST}/pev_pro_proyecto/rendicion_items`,
                  rendir_rendicion: `${$BASE_REST}/pev_pro_proyecto/rendir`,
                  eliminar_rendicion: `${$BASE_REST}/pev_pro_proyecto/eliminar_rendicion`
            },
            eventos: {
                  view_eventos: `${$BASE_REST}/view_eventos`,
                  evento: `${$BASE_REST}/pev_pro_eventos`,
                  evento_compartido: `${$BASE_REST}/pev_pro_evento/compartido`,
                  configuracion_evento: `${$BASE_REST}/pev_pro_evento/configuracion`,
                  get_datos_configuracion: `${$BASE_REST}/pev_pro_evento/get_datos_configuracion`,
                  save_datos_configuracion: `${$BASE_REST}/pev_pro_evento/save_datos_configuracion`,
                  ver_certificado: `${$BASE_REST}`, // uploads/nombre_imagen se lo concatena en certificado.component.ts
                  pagos: {
                        resumen_pagos: `${$BASE_REST}/pev_ins_pago/resumen`
                  },
                  integrantes: {
                        integrantes_evento: `${$BASE_REST}/pev_pro_evento/integrantes`,
                        realizar_factura: `${$BASE_REST}/pev_ins_pago/facturar`
                  },
                  exportar_excel: `${$BASE_REST}/pev_pro_evento/excel`,
                  descarga_excel: `${$BASE_API}`, // /web/uploads/integrantes_evento_13_1733863995.xlsx se lo concatena cuando se realice la descarga
            },
            inscripciones: {
                  view_inscripciones: `${$BASE_REST}/pev_ins_inscripcion/integrantes`,
                  pagos : {
                        listado_pagos: `${$BASE_REST}/pev_ins_pago/listado`,
                        realizar_pago: `${$BASE_REST}/pev_ins_pago/realizar_pago`,
                        editar_pago: `${$BASE_REST}/pev_ins_pago/realizar_pago`,
                        pago_realizado: `${$BASE_REST}/pev_ins_pago/get_pago_realizado`,
                        pago_inscripcion_detalle: `${$BASE_REST}/pev_ins_pago/pago_detalle`,
                        eliminar_pago_realizado: `${$BASE_REST}/pev_ins_pago/eliminar_pago_realizado`,
                        eliminar_pago_inscripcion_realizada: `${$BASE_REST}/pev_ins_pago/eliminar_pago`
                  },
                  papelera: {
                        levantar_papelera: `${$BASE_REST}/pev_ins_pago/levantar_papelera`
                  }

            }
      },
      select: {
            configuracion: `${$BASE_REST}/select/configuracion?idconfiguraciontipo=`,
            buscadorEmpresa: `${$BASE_REST}/select?modelo=PevEmpEmpresa&campo_id=idempresa&campo_descripcion=razon_social&campo_activo=`,
            roles: `${$BASE_REST}/selects?modelo=PevSegRol&campo_id=idrol&campo_descripcion=descripcion&campo_activo=activo`,
            paises: `${$BASE_REST}/selects?modelo=PevComPais&campo_id=idpais&campo_descripcion=descripcion&campo_activo`,
            provincia: `${$BASE_REST}/select/provincia?idpais=`,
            localidad: `${$BASE_REST}/select/localidad?idprovincia=`,
            tipo_proyecto: `${$BASE_REST}/selects?modelo=PevProProyectoTipo&campo_descripcion=descripcion&campo_id=idproyectotipo&campo_activo=activo`,
            proyecto: `${$BASE_REST}/select?modelo=PevProProyecto&campo_id=idproyecto&campo_descripcion=nombre&campo_activo=`,
            genero: `${$BASE_REST}/select/configuracion?idconfiguraciontipo=22`,
            tipo_indumentaria: `${$BASE_REST}/select/configuracion?idconfiguraciontipo=23`,
            genero_indumentaria: `${$BASE_REST}/select/configuracion?idconfiguraciontipo=24`,
            talle_indumentaria: `${$BASE_REST}/select/configuracion?idconfiguraciontipo=25`,
            disciplina: `${$BASE_REST}/select/configuracion?idconfiguraciontipo=11`,
            estado_evento: `${$BASE_REST}/select/configuracion?idconfiguraciontipo=26`,
            disparador: `${$BASE_REST}/select/configuracion?idconfiguraciontipo=27`,
            medios_pago: `${$BASE_REST}/select/configuracion?idconfiguraciontipo=28`,
            forma_pago: `${$BASE_REST}/select/realizar_pago_forma_pago`
      },
};

