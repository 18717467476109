import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from '@core/auth/auth.service';

@Injectable({
    providedIn: 'root'
})
export class DatosService {
    private datosRoles: any = null;
    private datosUser: any = null;

    // constructor(private httpClient: HttpClient, private _authService: AuthService/* , private _navigationService: NavigationService */) { }
    constructor(private httpClient: HttpClient/* , private _navigationService: NavigationService */) { }

    getDatosUser() {
        return this.datosUser;
    }

    getDatosRoles(){
        return this.datosRoles;
    }

    setDatosUser(user){
        this.datosUser = user;
    }

    setDatosRoles(roles){
        this.datosRoles=roles;
    }

}
