import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { catchError, map, Observable, ReplaySubject, Subject, throwError } from 'rxjs';
import { IUsuario } from '../models/usuario';
import { AuthService } from '@core/auth/auth.service';
import { Response } from '@shared/models/response-with-token';

@Injectable()
export class UsuarioService {
    private usuariosSubject = new ReplaySubject<any>(1);
    constructor(
        private _httpClient: HttpClient,
        private _authService: AuthService
    ) { }
    /**
     * Funcion en el servicio
     * - Esta funcion retorna los Articulos.
     * @returns Articulos
     */
    getUsuarios(sort?: any): Observable<any> {
        let f = '?';
        f += sort == 'desc' ? 'sort=-descripcion' : 'sort=descripcion';
        this._httpClient.get<Response[]>(`${environment.administracion.usuarios.view_usuario}${f}`)
            .subscribe((resp: any) => {
                this._authService.accessToken = resp.token,
                this.usuariosSubject.next({
                    'list': resp.result,
                    'mostrar_empresa': resp.mostrar_empresa,
                    'puede_asignar_dueño': resp.puede_asignar_dueño,
                    'puede_asignar_rol': resp.puede_asignar_rol,
                    'puede_crear_usuario': resp.puede_crear_usuario,
                    'puede_inactivar': resp.puede_inactivar 
                })
            });
        return this.usuariosSubject.asObservable();
    }

    /**
     * Funcion en el servicio
     * @param idusuario
     * @returns Elimina un Usuario
     */
    deleteUsuario(idusuario: number): Observable<IUsuario> {
        return this._httpClient.delete<IUsuario>(environment.administracion.usuarios.usuario_datas + '/' + idusuario);
    }

    /**
     * Funcion en el servicio
     * @param usuario
     * @returns Usuario actualizado actualizado
     */
    updateUsuario(usuario: IUsuario, id:number): Observable<any> {
        return this._httpClient.put<IUsuario>(`${environment.administracion.usuarios.usuario_datas}` + '/' + id, usuario);
    }

    /**
        * Funcion en el Servicio 
        * @param usuario 
        * @returns Crea un Usuario nuevo
        */
    saveUsuario(usuario: IUsuario): Observable<any> {
        return this._httpClient.post<IUsuario>(`${environment.administracion.usuarios.usuario_datas}`, usuario)
    }

    /**
         * Funcion en el Servicio 
         * @returns Devuelve los roles de los usuarios
         */
    traerRoles(payload: any): Observable<any> {
        return this._httpClient.post<any>(`${environment.administracion.usuarios.usuarioRoles}`,payload).pipe(
            map((res: any) => {
                this._authService = res.token;
                return res;
            }),
            catchError(error => {
                console.error('Error en la llamada GET', error);
                return throwError(error);
            })
        );
    }

    traerEmpresa(payload: any): Observable<any> {
        return this._httpClient.post<any>(`${environment.administracion.usuarios.getDueniosAsignarEmpresa}`,payload).pipe(
            map((res: any) => {
                this._authService = res.token;
                return res;
            }),
            catchError(error => {
                console.error('Error en la llamada POST', error);
                return throwError(error);
            })
        );
    }

    traerRolesParaAsignar(payload: any): Observable<any> {
        return this._httpClient.post<any>(`${environment.administracion.usuarios.asignarRolAUsuario}`,payload).pipe(
            map((res: any) => {
                this._authService = res.token;
                return res;
            }),
            catchError(error => {
                console.error('Error en la llamada POST', error);
                return throwError(error);
            })
        );
    }

    asignarDuenioEmpresa(payload: any): Observable<any> {
        return this._httpClient.post<any>(`${environment.administracion.usuarios.asignarEmpresaGuardar}`,payload).pipe(
            map((res: any) => {
                this._authService = res.token;
                return res;
            }),
            catchError(error => {
                console.error('Error en la llamada POST', error);
                return throwError(error);
            })
        );
    }

    asignarRolAUsuario(payload: any): Observable<any> {
        return this._httpClient.post<any>(`${environment.administracion.usuarios.asignarRolAUsuarioGuardar}`,payload).pipe(
            map((res: any) => {
                this._authService = res.token;
                return res;
            }),
            catchError(error => {
                console.error('Error en la llamada POST', error);
                return throwError(error);
            })
        );
    }

    inactivarRol(payload: any): Observable<any> {
        return this._httpClient.post<any>(`${environment.administracion.usuarios.inactivarUsuarioRoles}`,payload)
        
    }
}