/* eslint-disable */

import { FuseNavigationItem } from "@fuse/components/navigation";

export const defaultNavigation: FuseNavigationItem[] = [
  
];
export const compactNavigation: FuseNavigationItem[] = [
   
];
export const futuristicNavigation: FuseNavigationItem[] = [
    
];
export const horizontalNavigation: FuseNavigationItem[] = [
    
];
