
export const TitleType = [
  { icon: "", text: "Inicio", path: "/home" },
  { icon: "", text: "Seleccion de Roles", path: "/seleccion-roles" },
  { icon: "", text: "Empresa", path: "/empresas" },
  { icon: "", text: "Usuarios", path: "/usuarios" },
  { icon: "", text: "Proyectos", path: "/proyectos"},  
  { icon: "", text: "Eventos", path: "/eventos"},  
  { icon: "", text: "Alta Eventos", path: "/eventos/alta-eventos"},  
  { icon: "", text: "Configuración Eventos", path: "/eventos/configuracion-eventos"},
  { icon: "", text: "Inscripciones", path: "/inscripciones" }  
];
